<template>
    <div class="grow-custom-1 padding-custom-1 flex-box flexcol ai-center jc-center">
        <div class="grow-custom-1 w-100-ps overflow-auto flex-box flexcol">
            <iframe width="100%" height="100%"  frameborder="0" :src="xfile">
            </iframe>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FileComponent',
        components: {
        },        
        data() {
            return {
                vpath : window.location.pathname,
                key01 : '',
                xmsg : '',
                xfile : '',
            }
        },
        mounted() {
            this.getPath(this.vpath)
        },
        methods : {
            getPath(xpath){                
                let xpos = xpath.toString().split("/")
                this.decodeNumber(xpos[2])
            },
            decodeNumber(xid){
                this.$axios.get(this.$docs+'/decryptkey/'+xid)
				.then(response => {
                    this.getDocument(response.data.key)  
                })
            },
            getDocument(xid){
                this.searchData(xid)
            },
            searchData(key01){
                let decodekey = decodeURIComponent(key01)
                this.$axios.get(this.$docs+'/search', { params : {key01:decodekey}})
                .then(response => {
                    if (response.data.message != '0')
                    {
                        this.xmsg = 'Please Wait...'
                        this.getFile(response.data.xfullname)
                    }
                    else
                    {
                        this.xmsg = 'File Not Found.'
                        this.getFile('file_not-found.pdf')
                    }
                })
                .catch(error => {
                    let errkode = error.response.status
                    console.log(errkode)
                })
            },  
            getFile(xfullname){
                this.xmsg = ''
                this.xfile = this.$docs+'/files/'+xfullname                
            },
        }
    }
</script>