import axios from 'axios';

const MyConfig = {
    install(app) {
        app.config.globalProperties.$axios = axios;
        app.config.globalProperties.$companyname = 'PT. Faika Sarana Mulia';
        app.config.globalProperties.$apptitle = 'FSM E-Report';
        app.config.globalProperties.$appsubtitle = 'Documents';
        app.config.globalProperties.$processingmsg = 'Please Wait';
        app.config.globalProperties.$500msg = 'Internal Server Error';
        app.config.globalProperties.$404msg = 'Page Not Found';
        app.config.globalProperties.$429msg = 'Try again in 5 minutes, or restart the application.';
        app.config.globalProperties.$unknownerr = 'Call Administrator';
        app.config.globalProperties.$idcode = '+62';
        //--api--production--//
        app.config.globalProperties.$svrapi = 'https://e-report.fsm.co.id';
        app.config.globalProperties.$pmrapi = 'https://backend.fsm.co.id';
        app.config.globalProperties.$pmapi = 'https://pmr2backend.fsm.co.id';
        app.config.globalProperties.$astroapi = 'https://pmr3backend.fsm.co.id';
        app.config.globalProperties.$urlsign = 'https://e-sign.fsm.co.id';
        app.config.globalProperties.$docs = 'https://docsbackend.fsm.co.id';
        app.config.globalProperties.$urlapp = 'https://docs.fsm.co.id';
        //--end--//
        //--api--local--//
        //app.config.globalProperties.$svrapi = 'http://maintenanceapp.test';
        //app.config.globalProperties.$pmrapi = 'http://pmrbackend.test';
        //app.config.globalProperties.$pmapi = 'http://pmbackend.test';
        //app.config.globalProperties.$astroapi = 'http://astrobackend.test';
        //app.config.globalProperties.$urlsign = 'http://e-sign.test';
        //app.config.globalProperties.$docs = 'http://docsbackend.test';
        //app.config.globalProperties.$urlapp = 'http://localhost:8080';
        //--end--//
    },
}

export default MyConfig